<template>
  <layout-vertical :nav-menu-items="navMenuItems">
    <router-view />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'

export default {
  components: {
    LayoutVertical,
  },
  data() {
    return {
      navMenuItems,
    }
  },
}
</script>
