/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import system from './system'
import contentManagement from './content-management'
import telemarketing from './telemarketing'
import report from './report'
import inventory from './inventory'
// Array of sections
export default [
	...dashboard,
	...inventory,
	...report,
	/* ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others, */
	...contentManagement,
	...telemarketing,
	...system,
]
