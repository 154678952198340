export default [
    {
        header: 'Report',
    },
    {
        title: 'Stock',
        route: 'stock',
        icon: 'FileTextIcon',
    },
    {
        title: 'Transaction',
        route: 'transaction-report',
        icon: 'FileTextIcon',
    },
]
