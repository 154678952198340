export default [
	{
		header: 'Content Mangement',
	},
	{
		title: 'Pages',
		icon: 'LayoutIcon',
		route: 'content-management-page',
	},
]
