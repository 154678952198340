export default [
	{
		title: 'Dashboards',
		icon: 'HomeIcon',
		/*
																			 tag: '2',
																			 tagVariant: 'light-warning', */

		route: 'dashboard',
		/* children: [
		/!*{
		title: 'eCommerce',
		route: 'dashboard-ecommerce',
		},*!/
		{
		title: 'Analytics',
		route: 'dashboard-analytics',
		},
	], */
	},
	{
		header: 'Transaksi',
	},
	{
		title: 'Order',
		icon: 'DollarSignIcon',
		route: 'order',
	},
	{
		title: 'Produk',
		route: 'apps-produk-list',
		icon: 'PackageIcon',
	},
	{
		title: 'Customers',
		route: 'apps-customers-list',
		icon: 'UsersIcon',
	},
	{
		title: 'Promo',
		icon: 'PercentIcon',
		children: [
			{
				title: 'Voucher',
				route: 'apps-discount',
				icon: 'PercentIcon',
			},
			{
				title: 'Flash Sale',
				route: 'master-flashsale',
				icon: 'ZapIcon',
			},
		],
	},
	{
		title: 'Return Order',
		route: 'return_sales',
		icon: 'PackageIcon',
	},
	{
		title: 'Refunds',
		route: 'refunds',
		icon: 'DollarSignIcon',
	},
	{
		title: 'FAQ',
		route: 'faq',
		icon: 'HelpCircleIcon',
	},
	// {
	// 	title: 'Invoice',
	// 	icon: 'FileTextIcon',
	// 	route: 'apps-invoice-list',
	// 	children: [
	// 		{
	// 			title: 'List',
	// 			route: 'apps-invoice-list',
	// 		},
	// 		{
	// 			title: 'Preview',
	// 			hidden: 'true',
	// 			route: { name: 'apps-invoice-preview', params: { id: 4987 } },
	// 		},
	// 	],
	// },
	// {
	// 	title: 'Bank Confirmation',
	// 	icon: 'CreditCardIcon',
	// 	route: 'apps-invoice-bankconfirm-list',
	// },
]
