export default [
	{
		header: 'Inventory',
	},
    {
        title: 'Penerimaan',
        route: 'apps-penerimaan-list',
        icon: 'BoxIcon',
    },
    {
        title: 'Retur Penerimaan',
        route: 'apps-returpenerimaan-list',
        icon: 'PackageIcon',
    },
]