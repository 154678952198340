export default [
  {
    header: 'System',
  },
  {
    title: 'Admin Users',
    icon: 'UserIcon',
    route: 'apps-users-list',
  },
  {
    title: 'Master',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Kategori Produk',
        route: 'master-kategoriproduk',
      },
      {
        title: 'Warehouse',
        route: 'warehouses',
      },
      {
        title: 'Review',
        route: 'master-review',
      },
      {
        title: 'Level Member',
        route: 'master-levelmember',
      },
      {
        title: 'Payment Step',
        route: 'payment-step',
      },
      {
        title: 'Service Center',
        route: 'service-center',
      },
      {
        title: 'Tag Produk',
        route: 'tags',
      },
    ],
  },
]
